import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYouPage = () => (
    <Layout>
    <SEO title="Contact Us" />
		<nav aria-label="breadcrumb" className="breadcrumbs-section border-top border-bottom mb-0">
			<div className="container">
				<ol className="breadcrumb mb-0 py-2 px-0">
					<li className="breadcrumb-item">
					<a href="/">Home</a></li>
					<li className="breadcrumb-item active">Contact Us</li>
				</ol>
			</div>
		</nav>
        <section className="intro-section pt-10 pb-5 pt-lg-26 pb-lg-15">
            <div className="container">
                <div className="row align-items-center justify-content-between">
                    <div className="col-12 col-md-7 col-lg-6 mb-8 mb-md-0">
                    <h1 className="mb-3 mb-4">Contact Us</h1>
                    <p className="contact-intro">To discuss your next project call us on <a href="tel:0394291318">(03) 9429 1318</a> or complete the form below. Alternatively you can email us at <a href="mailto:enquiries@webplace.com.au">enquiries@webplace.com.au</a>.</p>
                    </div>
                    <div className="col-12 col-md-5 col-lg-6 text-center text-md-right d-print-none">
                        <div className="img-holder"> 
                            <img src={'/intro-2.svg'} alt="" width="" height="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section className="form-section">
<div className="container">
   
   <div className="tab_surround pl-sm-30 pl-5 pr-sm-30 pr-5 pt-10 pb-20">
      <div className="contact-block" id="contact">
         <div className="frm_forms " id="frm_form_2_container">
            
               <div  className="frm_form_fields ">
                  
                     <section className="form-section">
                        <div className="container">
                           <h2 className="section-heading text-default text-center mb-5 mb-md-10 w-700">Email us</h2>
                           <p className="text-center"><strong>Submission successful</strong></p>
                           <p className="text-center">Our team will be in contact shortly. If you would like to speak with someone directly, <br />please call us on <a href="tel:0394291318">03 9429 1318</a></p> 
                        </div>
                     </section>
               </div>
         </div>
      </div>
   </div>
</div>
</section>
  </Layout>
)

export default ThankYouPage
